/* eslint-disable no-use-before-define */
import { alpha } from '@mui/material/styles';
import type { Theme } from '@mui/material/styles';
import { unstable_useEnhancedEffect as useEnhancedEffect } from '@mui/utils';
import { makeStyles } from '@mui/styles';
import {
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
  LocalShipping as LocalShippingIcon,
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { Helmet } from 'react-helmet-async';
import SelectAccount from 'components/Buttons/SelectAccount';
import LogoSymbolWhite from 'components/Img/bpswhite.png';
import _get from 'lodash.get';
import PropTypes from 'prop-types';
import { memo, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useSettings } from 'redux/modules/settings';
import BringerUISwitch from 'components/BringerSwitchTheme/BringerSwitchTheme';
import useIsWithDown from 'hooks/useIsWidthDown';
import useWidth from 'hooks/useWith';
import PageContext from 'components/PageContext/PageContext';
import AppNavDrawerItem from 'components/AppNavDrawerItem/AppNavDrawerItem';
import BringerLogo from 'components/Img/logo-1.png';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import deepEqual from 'deep-equal';
import {
  AppBar,
  Divider as DividerRaw,
  Drawer,
  IconButton,
  List,
  useTheme,
  darken,
  SwipeableDrawer,
  Toolbar,
  Tooltip,
  Box,
  useMediaQuery,
  Stack,
  styled,
  // Alert,
} from '@mui/material';
import type { MuiPage } from './pages';

const drawerWidth = 240;
const drawerWidthMini = 90;

const savedScrollTop: any = {};

const Divider = styled(DividerRaw)(({ theme }) => {
  return {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.divider : darken('rgba(34,74,190,1)', 0.1),
  };
});

function PersistScroll(props) {
  const { slot, children, enabled } = props;
  const rootRef = useRef();

  useEnhancedEffect(() => {
    const parent = rootRef.current ? rootRef.current.parentElement : null;
    const activeElement = parent.querySelector('.app-drawer-active');

    if (!enabled || !parent || !activeElement || !activeElement.scrollIntoView) {
      return undefined;
    }

    parent.scrollTop = savedScrollTop[slot];

    const activeBox = activeElement.getBoundingClientRect();

    if (activeBox.top < 0 || activeBox.top > window.innerHeight) {
      parent.scrollTop += activeBox.top - 8 - 32;
    }

    return () => {
      savedScrollTop[slot] = parent.scrollTop;
    };
  }, [enabled, slot]);

  return <div ref={rootRef}>{children}</div>;
}

PersistScroll.propTypes = {
  children: PropTypes.node.isRequired,
  enabled: PropTypes.bool.isRequired,
  slot: PropTypes.string.isRequired,
};

// https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
const ToolbarIE11 = styled('div')({ display: 'flex' });

const ToolbarDiv = styled('div')(({ theme }) => {
  return {
    ...theme.mixins.toolbar,
    paddingLeft: theme.spacing(2),
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
});

const StyledDrawer = styled(Drawer)(({ theme }) => {
  return {
    [theme.breakpoints.up('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  };
});

const SwipeableDrawerPaperComponent = styled('div')({
  width: 250,
  boxShadow: 'none',
});

function renderNavItems(options) {
  const { pages, ...params } = options;

  return (
    <List disablePadding>
      {pages.reduce((items, page) => reduceChildRoutes({ items, page, ...params }), [])}
    </List>
  );
}

function reduceChildRoutes(context: { page: MuiPage }) {
  const {
    onClose,
    activePage,
    items,
    depth,
    desktopCollapsed,
    dispatch: dispatchNotifier,
    mobileOpen,
    user,
    userAccountSetting,
  } = context;
  let { page } = context;
  if (page.ordered === false) {
    return items;
  }

  const roles = user && user.roles;

  const userAccounts = user && user.accounts;

  const _userAccount =
    userAccountSetting &&
    userAccounts &&
    userAccounts.find((ua: any) => userAccountSetting.id === ua.account.id);
  /** @type {import('../../../api/database/models').Account} */
  const userAccount = _userAccount && _userAccount.account;

  if (page.children && page.children.length > 1) {
    const title = page.label;
    const topLevel = activePage
      ? activePage.pathname.indexOf(`${page.pathname}/`) === 0 ||
        page.children.some((child) => {
          return activePage.pathname.indexOf(`${child.pathname}`) === 0;
        })
      : false;

    if (page.roles) {
      if (roles && !roles.some((role: any) => page.roles.includes(role.role.role))) {
        return items;
      }

      if (userAccount && userAccount.account_role) {
        if (!page.roles.includes(userAccount.account_role.role)) {
          return items;
        }
      }
    }

    items.push(
      <AppNavDrawerItem
        linkProps={page.linkProps}
        depth={depth}
        key={title}
        topLevel={topLevel && !page.subheader}
        openImmediately={topLevel || Boolean(page.subheader)}
        title={title}
        icon={page.icon}
        onClick={page.onClick}
        desktopCollapsed={desktopCollapsed}
        mobileOpen={mobileOpen}
      >
        {renderNavItems({
          onClose,
          pages: page.children,
          activePage,
          depth: depth + 1,
          desktopCollapsed,
          mobileOpen,
          dispatch: dispatchNotifier,
          user,
          userAccountSetting,
        })}
      </AppNavDrawerItem>
    );
  } else {
    if (page.roles) {
      if (roles && !roles.some((role: any) => page.roles.includes(role.role.role))) {
        return items;
      }

      if (userAccount && userAccount.account_role) {
        if (!page.roles.includes(userAccount.account_role.role)) {
          return items;
        }
      }
    }

    const title = page.label;
    page = page.children && page.children.length === 1 ? page.children[0] : page;

    items.push(
      <AppNavDrawerItem
        linkProps={page.linkProps}
        depth={depth}
        key={title}
        title={title}
        href={page.pathname}
        onClick={(e) => {
          if (page.onClick) {
            page.onClick({ dispatch });
          }
          const disabledAutoClosed = true;
          if (!disabledAutoClosed) {
            onClose(e);
          }
        }}
        icon={page.icon}
        desktopCollapsed={desktopCollapsed}
        mobileOpen={mobileOpen}
      />
    );
  }

  return items;
}

// iOS is hosted on high-end devices. We can enable the backdrop transition without
// dropping frames. The performance will be good enough.
// So: <SwipeableDrawer disableBackdropTransition={false} />
const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

function AppNavDrawer(props) {
  const {
    className,
    disablePermanent,
    mobileOpen,
    desktopCollapsed,
    onClose,
    onOpen,
    data,
    extraItems,
    dispatch: dispatchNotifier,
    style,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const theme = useTheme();

  const { state: settings } = useSettings();
  const { uiTheme, account: userAccountSetting, servicePanelMode } = settings;

  const { activePage, pages: rawPages } = useContext(PageContext);

  const pages = useMemo(() => {
    if (servicePanelMode === 'wholesaler') {
      return rawPages.filter((page) => page.roles?.includes('wholesaler'));
    }

    return rawPages;
  }, [servicePanelMode]);

  const user = useSelector((_state) => _get(_state, 'auth.user'));
  const accountByDomain = useSelector((state) => state.accounts.result.accountByDomain?.[0]);
  const drawer = useMemo(() => {
    const { accountLogo, classes } = data;
    const navItems = renderNavItems({
      onClose,
      pages,
      activePage,
      depth: 0,
      desktopCollapsed,
      mobileOpen,
      dispatch: dispatchNotifier,
      user,
      userAccountSetting: accountByDomain || userAccountSetting,
    });

    return (
      <OverlayScrollbarsComponent
        style={{ height: '100vh' }}
        options={{ scrollbars: { autoHide: 'scroll' } }}
        defer
      >
        <ToolbarIE11>
          <ToolbarDiv>
            <Helmet>
              <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/icon?family=Material+Icons"
              />
            </Helmet>
            {!desktopCollapsed && (
              <Link to="/" onClick={onClose}>
                <Box component="a" aria-label="Go To Home" sx={{ lineHeight: 0, mr: 2 }}>
                  {/* <SvgMuiLogo width={32} /> */}
                  <img
                    src={
                      accountLogo ||
                      (theme.palette.mode === 'light' ? LogoSymbolWhite : LogoSymbolWhite)
                    }
                    alt="BPS"
                    className={classes.bpsLogo}
                  />
                </Box>
              </Link>
            )}
            <IconButton
              onClick={(!mobile && desktopCollapsed) || (mobile && mobileOpen) ? onOpen : onClose}
              size="large"
            >
              {theme.direction === 'rtl' || desktopCollapsed ? (
                <ChevronRightIcon sx={{ color: '#fff' }} />
              ) : (
                <ChevronLeftIcon sx={{ color: '#fff' }} />
              )}
            </IconButton>
          </ToolbarDiv>
        </ToolbarIE11>
        <Divider />
        {navItems}
        {extraItems || null}
        <Box sx={{ height: 40 }} />
      </OverlayScrollbarsComponent>
    );
  }, [
    activePage,
    desktopCollapsed,
    mobileOpen,
    mobile,
    theme.palette.mode,
    pages,
    onClose,
    anchorEl,
    setAnchorEl,
    JSON.stringify(uiTheme),
    JSON.stringify(userAccountSetting),
  ]);

  return (
    <nav className={className} aria-label="Main Navegation">
      {disablePermanent || mobile ? (
        <SwipeableDrawer
          disableBackdropTransition={!iOS}
          variant="temporary"
          open={mobileOpen}
          onOpen={onOpen}
          onClose={onClose}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            className: 'algolia-drawer',
            component: SwipeableDrawerPaperComponent,
            sx: {
              borderRightColor: (_theme) =>
                // _theme.palette.mode === 'dark' ? _theme.palette.primaryDark[900] : '#fff',
                _theme.palette.mode === 'dark'
                  ? _theme.palette.divider
                  : darken('rgba(34,74,190,1)', 0.1),
              background: (_theme) =>
                // _theme.palette.mode === 'dark' ? _theme.palette.primaryDark[900] : '#fff',
                _theme.palette.mode === 'dark'
                  ? _theme.palette.primaryDark[900]
                  : 'linear-gradient(to bottom, rgba(34,74,190,0.95) 0%,rgba(78,115,223,0.85) 100%)',
              ...(style && style),
            },
          }}
        >
          <PersistScroll slot="swipeable" enabled={mobileOpen}>
            {drawer}
          </PersistScroll>
        </SwipeableDrawer>
      ) : null}
      {disablePermanent || mobile ? null : (
        <StyledDrawer
          variant="permanent"
          PaperProps={{
            component: SwipeableDrawerPaperComponent,
            sx: {
              borderRightColor: (_theme) =>
                // _theme.palette.mode === 'dark' ? _theme.palette.primaryDark[900] : '#fff',
                _theme.palette.mode === 'dark'
                  ? _theme.palette.divider
                  : darken('rgba(34,74,190,1)', 0.1),
              background: (_theme) =>
                // _theme.palette.mode === 'dark' ? _theme.palette.primaryDark[900] : '#fff',
                _theme.palette.mode === 'dark'
                  ? _theme.palette.primaryDark[900]
                  : 'linear-gradient(to bottom, rgba(34,74,190,0.95) 0%,rgba(78,115,223,0.85) 100%)',
              ...(style && style),
            },
          }}
          open
        >
          <PersistScroll slot="side" enabled>
            {drawer}
          </PersistScroll>
        </StyledDrawer>
      )}
    </nav>
  );
}

AppNavDrawer.propTypes = {
  className: PropTypes.string,
  disablePermanent: PropTypes.bool.isRequired,
  mobileOpen: PropTypes.bool.isRequired,
  desktopCollapsed: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  extraItems: PropTypes.any,
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const StyledAppNavDrawer = styled(AppNavDrawer)(({ disablePermanent, theme, desktopCollapsed }) => {
  if (disablePermanent) {
    return {};
  }
  return {
    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
      width: drawerWidth,
      ...(!desktopCollapsed && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': {
          ...openedMixin(theme),
        },
      }),
      ...(desktopCollapsed && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': {
          ...closedMixin(theme),
        },
      }),
    },
  };
});

const NavIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'disablePermanent',
})(({ disablePermanent, desktopCollapsed, theme }) => {
  if (disablePermanent) {
    return {};
  }
  return {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: !desktopCollapsed ? 'none' : 'inherit',
    },
  };
});

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'disablePermanent',
})(({ disablePermanent, theme, desktopCollapsed }) => {
  return {
    padding: '0 2px',
    transition: theme.transitions.create('width'),
    ...(disablePermanent && {
      boxShadow: 'none',
    }),
    ...(!disablePermanent &&
      !desktopCollapsed && {
        [theme.breakpoints.up('sm')]: {
          width: 'calc(100% - 240px)',
        },
      }),
    boxShadow: 'none',
    borderStyle: 'solid',
    backdropFilter: 'blur(8px)',
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.primaryDark[700] : theme.palette.grey[100],
    borderWidth: 0,
    borderBottomWidth: 'thin',
    background:
      theme.palette.mode === 'dark'
        ? theme.palette.primaryDark[900]
        : alpha(theme.palette.primary.main, 0.8),
    color: theme.palette.mode === 'dark' ? theme.palette.grey[500] : theme.palette.grey[800],
    '& .MuiIconButton-root': {
      border: `1px solid ${
        theme.palette.mode === 'dark' ? theme.palette.primaryDark[600] : alpha('#fff', 0.6) // theme.palette.grey[200]
      }`,
      borderRadius: theme.shape.borderRadius,
      // color: theme.palette.mode === 'dark' ? '#FFF' : theme.palette.primary[500],
      color: theme.palette.mode === 'dark' ? '#FFF' : '#fff',
      background:
        theme.palette.mode === 'dark' ? theme.palette.primaryDark[800] : theme.palette.primary.main,
    },
    ...(desktopCollapsed && {
      [theme.breakpoints.up('sm')]: {
        marginLeft: drawerWidthMini,
        width: `calc(100% - ${drawerWidthMini}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    }),
  };
});

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
    },
    bpsLogo: {
      height: 30,
      maxWidth: '100%',
      margin: '0 auto',
    },
    appBar: {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'zIndex' does not exist on type 'DefaultT... Remove this comment to see the full error message
      zIndex: theme.zIndex.drawer + 1,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitions' does not exist on type 'Def... Remove this comment to see the full error message
      transition: theme.transitions.create(['width', 'margin'], {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitions' does not exist on type 'Def... Remove this comment to see the full error message
        easing: theme.transitions.easing.sharp,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitions' does not exist on type 'Def... Remove this comment to see the full error message
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
      [theme.breakpoints.up('sm')]: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitions' does not exist on type 'Def... Remove this comment to see the full error message
        transition: theme.transitions.create(['width', 'margin'], {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitions' does not exist on type 'Def... Remove this comment to see the full error message
          easing: theme.transitions.easing.sharp,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitions' does not exist on type 'Def... Remove this comment to see the full error message
          duration: theme.transitions.duration.enteringScreen,
        }),
        '& $bpsLogoTop': {
          display: 'none',
          opacity: 0,
        },
      },
    },
    menuButton: {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      marginRight: theme.spacing(1),
    },
    hide: {
      display: 'none',
    },
    hideDesktopOpen: {
      display: 'none',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
      [theme.breakpoints.down('md')]: {
        display: 'inherit',
      },
    },
    hideMobileOpen: {
      display: 'none',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
      [theme.breakpoints.up('sm')]: {
        display: 'inherit',
      },
    },
    drawer: {
      color: '#fff',
      // width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerOpen: {
      background: 'linear-gradient(to bottom, rgba(34,74,190,0.8) 0%,rgba(78,115,223,0.8) 100%)',
      backdropFilter: 'blur(8px)',
      width: 0,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitions' does not exist on type 'Def... Remove this comment to see the full error message
      transition: theme.transitions.create('width', {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitions' does not exist on type 'Def... Remove this comment to see the full error message
        easing: theme.transitions.easing.sharp,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitions' does not exist on type 'Def... Remove this comment to see the full error message
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerOpenMobile: {
      background: 'linear-gradient(to bottom, rgba(34,74,190,0.8) 0%,rgba(78,115,223,0.8) 100%)',
      backdropFilter: 'blur(8px)',
      width: drawerWidth,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitions' does not exist on type 'Def... Remove this comment to see the full error message
      transition: theme.transitions.create('width', {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitions' does not exist on type 'Def... Remove this comment to see the full error message
        easing: theme.transitions.easing.sharp,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitions' does not exist on type 'Def... Remove this comment to see the full error message
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      border: 0,
      background: 'linear-gradient(to bottom, rgba(34,74,190,0.8) 0%,rgba(78,115,223,0.8) 100%)',
      backdropFilter: 'blur(8px)',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitions' does not exist on type 'Def... Remove this comment to see the full error message
      transition: theme.transitions.create('width', {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitions' does not exist on type 'Def... Remove this comment to see the full error message
        easing: theme.transitions.easing.sharp,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitions' does not exist on type 'Def... Remove this comment to see the full error message
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: 0,
      // [theme.breakpoints.up('sm')]: {
      //   width: theme.spacing(9) + 1,
      // },
      '& $itemText': {
        opacity: 0,
      },
      '& $expandIcon': {
        marginLeft: -10,
      },
    },
    expandIcon: {},
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      padding: theme.spacing(0, 1),
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'mixins' does not exist on type 'DefaultT... Remove this comment to see the full error message
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      padding: theme.spacing(3),
    },
    drawerMenuContainer: {
      maxHeight: '95vh',
      overflow: 'auto',
    },
    submenu: {
      background: 'rgba(0,0,0, 0.3)',
    },
    nexted: {},
    linkLogo: {
      margin: '0 auto',
      paddingLeft: 40,
    },
    bpsLogoTop: {
      marginLeft: 0,
      height: 30,
      maxWidth: '100%',
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      marginRight: theme.spacing(2),
      marginTop: -5,
      opacity: 1,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitions' does not exist on type 'Def... Remove this comment to see the full error message
      transition: theme.transitions.create(['display', 'opacity'], {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitions' does not exist on type 'Def... Remove this comment to see the full error message
        easing: theme.transitions.easing.sharp,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'transitions' does not exist on type 'Def... Remove this comment to see the full error message
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    itemText: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    itemIcon: {
      minWidth: 40,
    },
    buttonGroup: {
      border: '1px solid #fff',
    },
    buttonMargin: {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacing' does not exist on type 'Default... Remove this comment to see the full error message
      marginLeft: theme.spacing(2),
    },
  }),
  { name: 'AdminHeader' }
);

/**
 * Memoize dont need to be re-renderer
 */
// const BadgeHandler = ({ children, callback }: any) => {
//   useEffect(() => {
//     if (callback) {
//       callback();
//     }
//   }, []);
//   return children;
// };

// const storeCalls = {};

// @ts-expect-error ts-migrate(2339) FIXME: Property 'logout' does not exist on type '{ childr... Remove this comment to see the full error message
const Header = memo(({ logout, user, changeTheme, handleSettingsDrawerOpen }) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const currentWitdth = useWidth();
  const isWidthDown = useIsWithDown('sm', currentWitdth);

  const { activePage } = useContext(PageContext);

  const disableDrawer = false;
  const disablePermanent = activePage?.disableDrawer === true || disableDrawer === true;
  const accountByDomain = useSelector((state) => state.accounts.result.accountByDomain?.[0]);
  const allAccounts = useSelector((state: any) => state.accounts.result.allAccounts);

  const { state: settings, set } = useSettings({
    shouldUpdate: {
      toJSON: () => 'HeadderUiSettings',
      test: (prev, next) => {
        const result = !deepEqual(next, prev);
        return result;
      },
    },
  });

  const { drawerServiceOpen, drawerServiceOpenMobile, uiTheme, account: userAccount } = settings;

  const drawerOpen = isWidthDown ? drawerServiceOpenMobile : drawerServiceOpen;

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const mobileOpen = drawerServiceOpenMobile;

  const handleDrawerToggleMobile = () => {
    set({ drawerServiceOpenMobile: !drawerServiceOpenMobile });
  };

  const handleDrawerOpen = () => {
    if (isWidthDown) {
      handleDrawerToggleMobile();
    } else {
      set({ drawerServiceOpen: true, drawerServiceOpenMobile: false });
    }
  };

  const handleDrawerClose = () => {
    if (isWidthDown) {
      handleDrawerToggleMobile();
    } else {
      set({ drawerServiceOpen: false, drawerServiceOpenMobile: false });
    }
  };

  const handleNavDrawerOpen = () => {
    handleDrawerOpen();
  };
  const handleNavDrawerClose = useCallback(() => {
    handleDrawerClose();
  }, [isWidthDown, drawerServiceOpenMobile, drawerServiceOpen]);

  // select menu on mount
  // useEffect(() => {
  //   let firstIndex = null;
  //   let secondIndex = null;
  //   let thirdIndex = null;
  //   console.log(location.pathname, 'location.pathname');
  //   menuItems.forEach((item, index) => {
  //     if (item.url) {
  //       if (location.pathname === item.url) {
  //         firstIndex = index;
  //       }
  //     }

  //     if (item.items) {
  //       item.items.forEach((_item: any, _index: any) => {
  //         if (_item.url) {
  //           if (location.pathname === _item.url) {
  //             firstIndex = index;
  //             secondIndex = `item-${index}-${_index}`;
  //           }
  //         }

  //         if (_item.items) {
  //           _item.items.forEach((__item: any, __index: any) => {
  //             if (__item.url) {
  //               if (location.pathname === __item.url) {
  //                 firstIndex = index;
  //                 secondIndex = `item-${index}-${_index}`;
  //                 thirdIndex = `item-${index}-${_index}-${__index}`;
  //               }
  //             }
  //           });
  //         }
  //       });
  //     }
  //   });

  //   // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  //   setSelectedIndex(firstIndex);
  //   // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  //   setSelectedSubIndex(secondIndex);
  //   // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  //   setSelectedSubSubIndex(thirdIndex);
  // }, []);

  // const loadUndeliverable = () => {
  //   // just for dev enviorement and hot re-renders is called twice
  //   // @ts-expect-error ts-migrate(2339) FIXME: Property 'parcelsUndeliverableCount' does not exis... Remove this comment to see the full error message
  //   if (storeCalls.parcelsUndeliverableCount) {
  //     return;
  //   }
  //   // @ts-expect-error ts-migrate(2339) FIXME: Property 'parcelsUndeliverableCount' does not exis... Remove this comment to see the full error message
  //   storeCalls.parcelsUndeliverableCount = true;

  //   dispatch(
  //     findUndeliverableParcels('parcelsUndeliverableCount', {
  //       query: {
  //         status: [
  //           'pending_price_quote',
  //           'local_pickup_requested',
  //           'reship_confirmed',
  //           'pending_response',
  //         ],
  //         $limit: 1,
  //       },
  //     })
  //     // @ts-expect-error ts-migrate(2339) FIXME: Property 'catch' does not exist on type '{ key: an... Remove this comment to see the full error message
  //   ).catch(() => null);
  // };

  const handleWholeSallerView = () => {
    dispatch(
      set({
        servicePanelMode: settings.servicePanelMode === 'wholesaler' ? undefined : 'wholesaler',
      })
    );
  };

  // const loadParcelsPendingReview = () => {
  //   // just for dev enviorement and hot re-renders is called twice
  //   if (storeCalls.parcelsPendingCount) {
  //     return;
  //   }
  //   storeCalls.parcelsPendingCount = true;
  //   dispatch(findParcels('parcelsPendingCount', {
  //     query: {
  //       status: 'pending_dim_review',
  //       $limit: 1
  //     }
  //   })).catch(() => null);
  // };
  // const badgeCallbacks = {
  //   loadUndeliverable,
  //   // loadParcelsPendingReview
  // };

  // const badgeVars: any = {
  //   countUndeliverable,
  //   countParcelsPendingReview,
  // };

  const isAdmin = user && user.roles.some((r: any) => r.role.role === 'admin');

  let accounts = [];
  if (isAdmin && user?.accounts && allAccounts) {
    accounts = allAccounts.map((account) => {
      return { account };
    });
  }

  let accountDetails =
    accountByDomain ||
    (userAccount &&
      userAccount.uid &&
      user &&
      accounts?.length &&
      accounts.find((account) => account.account.uid === userAccount.uid));
  accountDetails = accountByDomain || (accountDetails ? accountDetails.account : null);
  const accountLogo =
    accountDetails && accountDetails.allowPlatformWhiteLabel && accountDetails.themeLogo;

  const sidebarTopColor =
    accountDetails && accountDetails.allowPlatformWhiteLabel && accountDetails.sidebarTopColor;

  const sidebarLeftColor =
    accountDetails && accountDetails.allowPlatformWhiteLabel && accountDetails.sidebarLeftColor;

  const stylesLeft = sidebarLeftColor ? { background: sidebarLeftColor } : {};
  const stylesTop = sidebarTopColor ? { background: sidebarTopColor } : {};

  // const roles = user && user.roles;

  // const drawer = (
  //   <>
  //     <Helmet>
  //       <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
  //     </Helmet>
  //     <div style={stylesLeft} className={classes.toolbar}>
  //       <Link className={classes.linkLogo} to="/">
  //         <img src={accountLogo || LogoSymbolWhite} alt="BPS" className={classes.bpsLogo} />
  //       </Link>
  //       <IconButton className={classes.color} onClick={handleDrawerClose} size="large">
  //         {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
  //       </IconButton>
  //     </div>
  //     <Divider />
  //     <div className={classes.drawerMenuContainer} style={stylesLeft}>
  //       <List dense>
  //         {menuItems.map((item, index) => {
  //           if (item.roles) {
  //             if (!roles) {
  //               return null;
  //             }

  //             if (!roles.some((role: any) => item.roles.includes(role.role.role))) {
  //               return null;
  //             }
  //           }
  //           const attributesForListItem = item.url
  //             ? {
  //                 to: item.url,
  //                 component: Link,
  //               }
  //             : {
  //                 component: 'div',
  //               };
  //           return (
  //             <Fragment key={`fragment-${item.label}`}>
  //               <ListItem
  //                 onClick={(event) => handleListItemClick(event, index)}
  //                 selected={selectedIndex === index}
  //                 button
  //                 key={item.label}
  //                 {...attributesForListItem}
  //               >
  //                 <ListItemIcon className={`${classes.itemIcon} ${classes.color}`}>
  //                   {item.badge ? (
  //                     // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  //                     <BadgeHandler callback={badgeCallbacks[item.badgeCallback]}>
  //                       {/* // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
  //                       <Badge badgeContent={badgeVars[item.badgeVar]} color="primary">
  //                         <item.icon />
  //                       </Badge>
  //                     </BadgeHandler>
  //                   ) : (
  //                     <item.icon />
  //                   )}
  //                 </ListItemIcon>
  //                 <ListItemText
  //                   className={`${classes.itemText} ${classes.color}`}
  //                   primary={item.label}
  //                 />
  //                 {item.items &&
  //                   (selectedIndex === index ? (
  //                     <ExpandLess className={`${classes.expandIcon} ${classes.color}`} />
  //                   ) : (
  //                     <ExpandMore className={`${classes.expandIcon} ${classes.color}`} />
  //                   ))}
  //               </ListItem>
  //               <Collapse in={selectedIndex === index} timeout="auto" unmountOnExit>
  //                 <List component="div" disablePadding className={classes.submenu} dense>
  //                   {item.items &&
  //                     item.items.map((subitem: any, _index: any) => {
  //                       if (subitem.roles) {
  //                         if (!roles) {
  //                           return null;
  //                         }

  //                         if (!roles.some((role: any) => subitem.roles.includes(role.role.role))) {
  //                           return null;
  //                         }
  //                       }

  //                       const attributesForListSubItem = subitem.url
  //                         ? {
  //                             to: subitem.url,
  //                             component: Link,
  //                           }
  //                         : {
  //                             component: 'div',
  //                           };
  //                       return (
  //                         <Fragment key={`fragment-items-${subitem.label + _index}`}>
  //                           <ListItem
  //                             // @ts-expect-error ts-migrate(2551) FIXME: Property 'nested' does not exist on type 'ClassNam... Remove this comment to see the full error message
  //                             className={classes.nested}
  //                             onClick={(event) =>
  //                               handleListSubItemClick(event, `item-${index}-${_index}`)
  //                             }
  //                             selected={selectedSubIndex === `item-${index}-${_index}`}
  //                             // @ts-expect-error ts-migrate(2783) FIXME: 'component' is specified more than once, so this u... Remove this comment to see the full error message
  //                             component={Link}
  //                             to={!!subitem.url && subitem.url}
  //                             button
  //                             key={`items-${subitem.label}`}
  //                             {...attributesForListSubItem}
  //                           >
  //                             <ListItemIcon className={`${classes.itemIcon} ${classes.color}`}>
  //                               {subitem.badge ? (
  //                                 // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  //                                 <BadgeHandler callback={badgeCallbacks[subitem.badgeCallback]}>
  //                                   <Badge
  //                                     badgeContent={badgeVars[subitem.badgeVar]}
  //                                     color="primary"
  //                                   >
  //                                     <subitem.icon />
  //                                   </Badge>
  //                                 </BadgeHandler>
  //                               ) : (
  //                                 <subitem.icon />
  //                               )}
  //                             </ListItemIcon>
  //                             <ListItemText
  //                               className={`${classes.itemText} ${classes.color}`}
  //                               primary={subitem.label}
  //                             />
  //                             {subitem.items &&
  //                               (selectedSubIndex === `item-${index}-${_index}` ? (
  //                                 <ExpandLess
  //                                   className={`${classes.expandIcon} ${classes.color}`}
  //                                 />
  //                               ) : (
  //                                 <ExpandMore
  //                                   className={`${classes.expandIcon} ${classes.color}`}
  //                                 />
  //                               ))}
  //                           </ListItem>
  //                           <Collapse
  //                             in={selectedSubIndex === `item-${index}-${_index}`}
  //                             timeout="auto"
  //                             unmountOnExit
  //                           >
  //                             <List
  //                               component="div"
  //                               disablePadding
  //                               className={classes.submenu}
  //                               dense
  //                             >
  //                               {subitem.items &&
  //                                 subitem.items.map((subsubitem: any, __index: any) => (
  //                                   <ListItem
  //                                     // @ts-expect-error ts-migrate(2551) FIXME: Property 'nested' does not exist on type 'ClassNam... Remove this comment to see the full error message
  //                                     className={classes.nested}
  //                                     onClick={(event: any) =>
  //                                       handleListSubSubItemClick(
  //                                         event,
  //                                         `item-${index}-${_index}-${__index}`
  //                                       )
  //                                     }
  //                                     selected={
  //                                       selectedSubSubIndex === `item-${index}-${_index}-${__index}`
  //                                     }
  //                                     component={Link}
  //                                     to={subsubitem.url}
  //                                     button
  //                                     key={`sub-items-${subsubitem.label}`}
  //                                   >
  //                                     <ListItemIcon
  //                                       className={`${classes.itemIcon} ${classes.color}`}
  //                                     >
  //                                       <subsubitem.icon />
  //                                     </ListItemIcon>
  //                                     <ListItemText
  //                                       className={`${classes.itemText} ${classes.color}`}
  //                                       primary={subsubitem.label}
  //                                     />
  //                                   </ListItem>
  //                                 ))}
  //                             </List>
  //                           </Collapse>
  //                         </Fragment>
  //                       );
  //                     })}
  //                 </List>
  //               </Collapse>
  //             </Fragment>
  //           );
  //         })}
  //       </List>
  //       <Divider />
  //       <List dense>
  //         <ListItem onClick={logout} button>
  //           <ListItemIcon className={`${classes.itemIcon} ${classes.color}`}>
  //             <LogoutIcon />
  //           </ListItemIcon>
  //           <ListItemText className={`${classes.itemText} ${classes.color}`} primary="Logout" />
  //         </ListItem>
  //       </List>
  //       {user && user.roles && user.roles.some((r: any) => r.role.role === 'admin') && (
  //         <>
  //           <Divider />
  //           <List dense>
  //             <ListItem button>
  //               <ListItemIcon className={`${classes.itemIcon} ${classes.color}`}>
  //                 <LocalShippingIcon />
  //               </ListItemIcon>
  //               <ListItemText
  //                 onClick={handleWholeSallerView}
  //                 className={`${classes.itemText} ${classes.color}`}
  //                 primary="Wholesaler / Retailer"
  //               />
  //             </ListItem>
  //           </List>
  //         </>
  //       )}
  //     </div>
  //   </>
  // );

  return (
    <>
      <StyledAppBar
        disablePermanent={disablePermanent}
        desktopCollapsed={!drawerServiceOpen}
        position="fixed"
        // className={clsx(classes.appBar, {
        //   [classes.appBarShift]: drawerServiceOpen,
        // })}
        sx={{
          borderBottomColor: (_theme) => {
            return _theme.palette.mode === 'dark'
              ? _theme.palette.divider
              : darken('rgba(34,74,190,1)', 0.1);
          },
        }}
        style={stylesTop}
      >
        <Toolbar>
          <NavIconButton
            edge="start"
            color="inherit"
            aria-label="Open Drawer"
            disablePermanent={disablePermanent}
            desktopCollapsed={!drawerServiceOpen}
            onClick={handleNavDrawerOpen}
          >
            <MenuIcon />
          </NavIconButton>
          {!drawerOpen && (
            <img
              src={
                theme.palette.mode === 'light'
                  ? accountLogo || BringerLogo
                  : accountLogo || LogoSymbolWhite
              }
              alt="BPS Logo"
              className={classes.bpsLogoTop}
            />
          )}

          <SelectAccount />

          {/* <Alert variant="filled" severity="warning" sx={{ ml: 5 }}> */}
          {/*  In observance of the New Year holiday, our offices will be closed on January 1, 2025. */}
          {/* </Alert> */}

          <div style={{ flexGrow: 1 }} />

          <Tooltip title="Toggle settings" enterDelay={300}>
            <IconButton onClick={handleSettingsDrawerOpen} sx={{ px: '10px', ml: 1 }}>
              <SettingsIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </StyledAppBar>
      <StyledAppNavDrawer
        disablePermanent={disablePermanent}
        onClose={handleNavDrawerClose}
        onOpen={handleNavDrawerOpen}
        mobileOpen={mobileOpen}
        desktopCollapsed={!drawerServiceOpen}
        data={{ accountLogo, LogoSymbolWhite, classes }}
        dispatch={dispatch}
        style={stylesLeft}
        extraItems={
          <div style={{ marginTop: 8 }}>
            <Divider />
            <Stack
              sx={{ pl: 1, pr: 1, pt: 1, pb: 1 }}
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Tooltip title={`Theme selected: ${uiTheme.paletteMode}`}>
                <BringerUISwitch
                  checked={
                    (uiTheme.paletteMode === 'system' && prefersDarkMode) ||
                    uiTheme.paletteMode === 'dark'
                  }
                  onClick={() => {
                    let newTheme = 'system';
                    const nextAfterSystem = prefersDarkMode ? 'light' : 'dark';
                    const nextAfterLightSystem = prefersDarkMode ? 'dark' : 'system';
                    const nextAfterDarkSystem = prefersDarkMode ? 'system' : 'light';
                    if (uiTheme.paletteMode === 'system') {
                      newTheme = nextAfterSystem;
                    } else if (uiTheme.paletteMode === 'light') {
                      newTheme = nextAfterLightSystem;
                    } else if (uiTheme.paletteMode === 'dark') {
                      newTheme = nextAfterDarkSystem;
                    }

                    changeTheme({ paletteMode: newTheme });
                  }}
                />
              </Tooltip>
              <Tooltip title="Logout">
                <IconButton onClick={logout}>
                  <LogoutIcon sx={{ color: '#fff' }} />
                </IconButton>
              </Tooltip>
            </Stack>
            <Divider />
            {user && user.roles && user.roles.some((r: any) => r.role.role === 'admin') && (
              <List disablePadding>
                <AppNavDrawerItem
                  title={settings.servicePanelMode ? 'Admin' : 'Wholesaler / Retailer'}
                  icon={LocalShippingIcon}
                  onClick={handleWholeSallerView}
                  topLevel
                  depth={0}
                />
              </List>
            )}
          </div>
        }
      />
    </>
  );
});

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'Named... Remove this comment to see the full error message
Header.propTypes = {
  logout: PropTypes.func.isRequired,
  changeTheme: PropTypes.func.isRequired,
  handleSettingsDrawerOpen: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Header;
